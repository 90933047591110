import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getCriteriaQuestions = createAsyncThunk(
  "criteriaQuestions/getQuestions",
  async (thunkAPI) => {
    const response = await secureApi
      .get("/admin/criteriaquestions/")
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSingleCriteriaQuestion = createAsyncThunk(
  "criteriaQuestions/getSingleCriteriaQuestion",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/criteriaquestions/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addCriteriaQuestion = createAsyncThunk(
  "criteriaQuestions/addCriteriaQuestion",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/criteriaquestions/", params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const editCriteriaQuestion = createAsyncThunk(
  "criteriaQuestions/editCriteriaQuestion",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/criteriaquestions/${params.id}`, params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteCriteriaQuestion = createAsyncThunk(
  "criteriaQuestions/deleteCriteriaQuestion",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/criteriaquestions/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getViewCriteria = createAsyncThunk(
  "criteriaQuestions/getViewCriteria",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`admin/criteriaAnswer/bysurvey/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
