import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getOnboardingQuestions = createAsyncThunk(
  "onboardingQuestions/getQuestions",
  async (thunkAPI) => {
    const response = await secureApi
      .get("/admin/onBoardingQuestions/")
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getSingleQuestionData = createAsyncThunk(
  "onboardingQuestions/getSingleQuestionData",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/onBoardingQuestions/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/addOnBoardingQuestion",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/onBoardingQuestions/", params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const editOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/editOnBoardingQuestion",
  async (params, thunkAPI) => {
    const response = await secureApi
      .patch(`/admin/onBoardingQuestions/${params.id}`, params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const deleteOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/deleteOnBoardingQuestion",
  async (id, thunkAPI) => {
    const response = await secureApi
      .delete(`/admin/onBoardingQuestions/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const askOnbordingQuestionaAgain = createAsyncThunk(
  "onboardingQuestions/askOnbordingQuestionaAgain",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/admin/onboardingQuestions/askagain", params)
      .catch((e) => {
        return e;
      });
    if (response.status) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getOnboardingAnswers = createAsyncThunk(
  "onboardingQuestions/getOnboardingAnswers",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/respondent/getonboardingAnswer/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
