import { combineReducers } from "redux";
import { themeSlice } from "./theme/themeSlice";
import { authSlice } from "./auth/authSlice";
import { userSlice } from "./user/userSlice";
import { organizationSlice } from "./organization/organizationSlice";
import { onboardingQuestionsSlice } from "./onboardingQuestions/onboardingQuestionsSlice";
import { criteriaQuestionSlice } from "./criteriaQuestions/criteriaQuestionsSlice";
import { mappingSlice } from "./mapping/mappingSlice";
import { contactsSlice } from "./contacts/contactsSlice";
import { runMatchAlgoSlice } from "./runMatchAlgo/runMatchAlgoSlice";
import { liveSurveySlice } from "./liveSurvey/liveSurveySlice";
import { surveyFeedbackSlice } from "./surveyFeedback/feedbackSlice";
import { surveySummarySlice } from "./surveySummary/surveySummarySlice";
import { respondentsSlice } from "./respondents/respondentsSlice";

const reducer = combineReducers({
  theme: themeSlice.reducer,
  auth: authSlice.reducer,
  users: userSlice.reducer,
  organization: organizationSlice.reducer,
  onboardingQuestions: onboardingQuestionsSlice.reducer,
  criteriaQuestions: criteriaQuestionSlice.reducer,
  mapping: mappingSlice.reducer,
  contacts: contactsSlice.reducer,
  algo: runMatchAlgoSlice.reducer,
  liveSurvey: liveSurveySlice.reducer,
  surveyFeedback: surveyFeedbackSlice.reducer,
  surveySummary: surveySummarySlice.reducer,
  respondents: respondentsSlice.reducer,
});

export default reducer;
