import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getAllRespondents,
  sendNotice,
  changeRespondentPassword,
} from "./respondentsThunk";

const initialState = {
  getAllRespondentsRes: { status: "", data: null },
  sendNoticeRes: { status: "", data: null },
  changeRespondentPasswordData: { status: "", data: null },
};

export const respondentsSlice = createSlice({
  name: "respondents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRespondents.pending.toString(), (state) => {
      return {
        ...state,
        getAllRespondentsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAllRespondents.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getAllRespondentsRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getAllRespondents.rejected.toString(), (state) => {
      return {
        ...state,
        getAllRespondentsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(sendNotice.pending.toString(), (state) => {
      return {
        ...state,
        sendNoticeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(sendNotice.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        sendNoticeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(sendNotice.rejected.toString(), (state) => {
      return {
        ...state,
        sendNoticeRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(changeRespondentPassword.pending.toString(), (state) => {
      return {
        ...state,
        changeRespondentPasswordData: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      changeRespondentPassword.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeRespondentPasswordData: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(changeRespondentPassword.rejected.toString(), (state) => {
      return {
        ...state,
        changeRespondentPasswordData: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
