import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../../components/shared-components/Loading";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/organization`}
          component={lazy(() => import(`./organization`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/organization-users/:id`}
          component={lazy(() => import(`./organization/OrganizationUserView`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/users/:id`}
          component={lazy(() => import(`./user/UserView`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/onboardingQuestions`}
          component={lazy(() => import(`./onboardingQuestions`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/onboarding`}
          component={lazy(() => import(`./onboarding`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/live-survey`}
          component={lazy(() => import(`./liveSurvey`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/addOnboardingQuestions/:questionId?`}
          component={lazy(() =>
            import(`./onboardingQuestions/addOnBoardingQuestions`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/criteriaQuestions`}
          component={lazy(() => import(`./criteriaQuestions`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/addCriteriaQuestions/:questionId?`}
          component={lazy(() =>
            import(`./criteriaQuestions/addCriteriaQuestions`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/mapping`}
          component={lazy(() => import(`./mapping`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/viewSurvey/:surveyId?`}
          component={lazy(() => import(`./viewSurvey`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/eligible-respondents/:surveyId?`}
          component={lazy(() => import(`./viewSurvey/EligibleRespondents`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/view-feedback/:surveyId?`}
          component={lazy(() => import(`./viewSurvey/ViewFeedback`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/view-summary/:surveyId?`}
          component={lazy(() => import(`./viewSummary`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/viewLogic/:surveyId?`}
          component={lazy(() => import(`./viewLogic`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/viewCriteria/:surveyId?`}
          component={lazy(() => import(`./viewCriteria`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/respondents/`}
          component={lazy(() => import(`./respondents`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/contacts/`}
          component={lazy(() => import(`./contacts`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
